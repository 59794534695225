<template>
  <navbar>
    <!-- Buttons -->
    <DialogSetting />
    <PublishedDomain />
  </navbar>
</template>

<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'
import Navbar from '@/views/build-landing-page/components/Navbar.vue'
import DialogSetting from './components/dialog/DialogSetting.vue'
import PublishedDomain from './components/dialog/PublishedDomain.vue'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
    }
  },
  components: { Navbar, DialogSetting, PublishedDomain },
}
</script>

<style lang="scss" scoped></style>
