<template>
  <v-app-bar
    elevation="0"
    height="60"
  >
    <v-row
      align="center"
    >
      <div class="logo-container">
        <img
          src="@/assets/images/marketa_logos-05.png"
          width="185"
        />
      </div>

      <v-spacer />

      <div
        class="
          button-container"
      >
        <slot />
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'

import { mdiContentSave, mdiPublish } from '@mdi/js'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiContentSave, mdiPublish },
      isDark,
    }
  },
}
</script>

<style lang="scss" scoped>
.row {
  flex-wrap: nowrap;
}

.logo-container {
  display: flex;
  align-items: center;
}

.superwa {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-left: 1rem;
}

.button-container {
  display: flex;
  align-items: center;
}
</style>
