<template>
  <div class="text-center">
    <v-dialog width="500" v-model="isDialogVisible">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-4" color="primary" dark v-bind="attrs" v-on="on"> Check domain status </v-btn>
      </template>

      <v-card max-width="617" max-height="274">
        <v-toolbar flat>
          <v-row>
            <v-col cols="2">
              <v-btn icon dark color="#111B21" @click="isDialogVisible = false">
                <v-icon> {{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-toolbar-title :class="'header'" class="text-center text-subtitle-1 font-weight-bold">
                Check domain status
              </v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <!-- ini cuma agar dia ketengah -->
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container v-if="!isValidDomain">
          <v-row class="mx-2">
            <v-col>
              <p class="font-weight-bold text-subtitle-2">There was a problem connecting your domain</p>
              <p class="font-weight-light text-subtitle-2">
                Your DNS records are not pointing to Marketa, so your domain is not connected to your site. Either you
                didn't update them correctly, or there was a problem saving your changes. Follow our step-by-step
                instructions to try connecting your domain again.
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="isValidDomain">
          <v-row class="mx-2">
            <v-col>
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="1">
                  <v-img max-height="10" max-width="15.45" src="../../../../assets/images/svg/Vector 8.svg"></v-img
                ></v-col>
                <v-col block>
                  <p class="mb-0 primary--text font-weight-bold text-subtitle-2">
                    Your domain is connected to Marketa
                  </p></v-col
                >
              </v-row>

              <p class="font-weight-light text-subtitle-2">
                Your domain was successfully connected to Marketa and your website is now live worldwide.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  name: 'CheckDomainStatusDialog',
  data() {
    return {
      isDialogVisible: false,
      isValidDomain: true,
    }
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style></style>
