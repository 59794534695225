<template>
  <v-dialog width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" label="Check" v-bind="attrs" v-on="on"> Publish </v-btn>
    </template>
    <v-card width="591">
      <v-card-title class="text-center text-h5">
        <v-img max-height="32" max-width="32" :aspect-ratio="16 / 16" src="../../../../assets/images/Ellipse 5.png">
          <div class="d-flex justify-center align-center">
            <v-img max-height="10" max-width="15.45" class="mt-2" src="../../../../assets/images/Vector 8.png"></v-img>
          </div>
        </v-img>
        <v-strong> Landing page has been published </v-strong>
      </v-card-title>

      <v-card-text>
        <template v-slot:append>
          <v-icon color="green"> mdi-arrow-up-bold </v-icon>
        </template>
        <v-text-field
          outlined
          label="You can find your page at"
          :value="publishedLandingPage"
          append-icon="mdi-map-marker"
          class="mx-5"
          :class="'success-bar'"
          hide-details="auto"
        ></v-text-field>
      </v-card-text>

      <v-row class="mx-7">
        <v-col class="d-flex flex-row-reverse">
          <v-btn outlined color="primary" class="mb-4">Go to Dashboard</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PublishedDomain',
  data() {
    return {
      publishedLandingPage: 'www.google.com',
      dialog: false,
    }
  },
}
</script>

<style>
.success-bar {
  margin: 0 48px;
}
</style>
