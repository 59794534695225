<template>
  <v-dialog width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="primary"
        label="Connect Domain"
        v-bind="attrs"
        v-on="on"
      >
        Connect Domain
      </v-btn>
    </template>
    <v-card max-width="617">
      <v-toolbar flat>
        <v-row>
          <v-col cols="2">
            <v-btn
              icon
              dark
              color="#111B21"
              @click="isDialogVisible = false"
            >
              <v-icon> {{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <v-toolbar-title
              :class="'header'"
              class="text-center text-subtitle-1 font-weight-bold"
            >
              Connect Domain
            </v-toolbar-title>
          </v-col>
          <v-col cols="2">
            <!-- ini cuma agar dia ketengah -->
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container>
        <v-row class="mx-2">
          <v-col
            cols="9"
            class="d-flex align-center"
          >
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="e.g. mystunningwebsite.com"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <ConnectedToMarketa />
          </v-col>
        </v-row>
        <div v-if="isDomainInputTaken">
          <v-row class="mx-2">
            <v-col cols="9">
              happyhouse.com is taken. Is it yours? <span color="primary"><router-link to="">Yes, I own this domain</router-link></span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div>
            <v-row class="mx-2">
              <v-col cols="9">
              </v-col>
            </v-row>
            <v-row class="mx-2">
              <v-col> Get one of these great domains, recommended for you: </v-col>
            </v-row>
            <div
              v-for="(item, index) in tableItems"
              :key="index"
            >
              <v-row class="d-flex justify-space-between mx-2 mr-5">
                <v-col>{{ item.name }}</v-col>
                <v-btn
                  outlined
                  color="primary"
                  class="align-center"
                  @click="$router.push({ name: 'landing-page-checkout', params: { domain: item.name } })"
                >
                  Get It
                </v-btn>
              </v-row>
              <v-divider class="mx-3 mb-3"></v-divider>
            </div>
          </div>
        </div>
        <div v-else>
          <v-row class="mx-2 d-flex align-center">
            <v-col cols="1">
              <v-img
                max-height="10"
                max-width="15.45"
                src="../../../../assets/images/svg/Vector 8.svg"
              ></v-img>
            </v-col>

            <v-col block>
              <p class="mb-0 mr-28">
                happyhome.com is available!
              </p>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import ConnectedToMarketa from './ConnectedToMarketa.vue'

export default {
  name: 'ConnectDomain',
  components: { ConnectedToMarketa },
  data() {
    return {
      isDomainInputTaken: true,
      tableItems: [{ name: 'happiehome.org' }, { name: 'happiehome.net' }, { name: 'happiehome.store' }],
      tableHeaders: [{ value: 'name' }],
      dialog: false,
    }
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style></style>
