<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined class="me-2" v-bind="attrs" v-on="on"> Save </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-toolbar flat>
          <v-row>
            <v-col cols="2">
              <v-btn icon dark color="#111B21" @click="isDialogVisible = false">
                <v-icon> {{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-toolbar-title :class="'header'" class="text-center text-subtitle-1 font-weight-bold">
                Let's set up your site
              </v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <!-- ini cuma agar dia ketengah -->
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider />
        <v-row no-gutters>
          <v-col>
            <v-text-field label="Site name" outlined class="mx-5 mt-6" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field label="Site address (URL)" outlined class="mx-5 mt-6" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <div :class="'dio-icon-container'">
              <v-img
                max-width="35px"
                max-height="35px"
                :class="'dio-icon-image-button'"
                :aspect-ratio="1 / 1"
                src="../../../../assets/images/SuperWA-Logo-3.png"
              ></v-img>
            </div>
          </v-col>
          <v-col>
            <v-file-input
              class="mr-20"
              outlined
              block
              truncate-length="17"
              label="Upload favicon"
              prepend-icon="mdi-camera"
              hide-details="auto"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="my-6">
          <v-col align-self="center">
            <strong> Domain: </strong>
            Not Connected
          </v-col>
          <v-col class="ml-45">
            <ConnectDomain />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-1 mr-2">
          <v-col class="d-flex flex-row-reverse">
            <v-btn label="Save" color="primary">Save</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiMapMarker } from '@mdi/js'
import ConnectDomain from './ConnectDomain.vue'
export default {
  name: 'DialogSetting',
  components: { ConnectDomain },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiMapMarker,
      },
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style>
* {
  font-weight: 400;
}

.dio-icon-container {
  display: flex;

  border-color: rgb(197, 190, 190);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-collapse: separate;

  width: 56px;
  height: 56px;

  margin-left: 20px;
}

.dio-icon-image-button {
  margin: auto;
}
</style>
